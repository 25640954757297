<template>
  <b-form-group class="mrg-bottom-16">
    <div class="d-flex">
      <label
        class="custom-checkbox-container font-montserrat privacy-policy-content"
      >
        By Signing up, you agree to our
        <b-link
          href="https://www.kingpin.global/privacy-policy"
          target="_blank"
          class="cursor-pointer hyperlink-text-style"
        >
          Privacy Policy</b-link>
        &
        <b-link
          href="https://www.kingpin.global/terms-and-conditions"
          target="_blank"
          class="cursor-pointer hyperlink-text-style"
        >
          Terms</b-link>.
        <input
          type="checkbox"
          @change="onCheck"
        >
        <span class="checkmark" />
      </label>
    </div>
  </b-form-group>
</template>
<script>
import { BLink, BFormGroup } from 'bootstrap-vue'

export default {
  name: 'PolicyAndTerms',
  components: {
    BLink,
    BFormGroup,
  },
  emits: ['on-accept-policy'],
  methods: {
    onCheck(event) {
      this.$emit('on-accept-policy', event.target.checked)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/vue/pages/signup/kp-signup-variables.scss';
@import '@/assets/scss/common-mixins.scss';
.privacy-policy-content {
  line-height: 140%;
  color: $have-an-account-text-clr;
  @include font-weight-and-size(500, 16px);
}
.custom-checkbox-container {
  padding-left: 30px;
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:hover ~ .checkmark {
      background-color: $light;
    }

    &:checked ~ .checkmark {
      background-color: $info;
      &:after {
        display: block;
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: $white;
    border: 1px solid $link-water;
    border-radius: 50%;

    &:after {
      content: '';
      position: absolute;
      display: none;
      width: 10px;
      height: 6px;
      left: 5px;
      top: 5px;
      border: 2px solid $white;
      border-top: none;
      border-right: none;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
}
</style>
